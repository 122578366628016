import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import PublicLayout from "../components/PublicLayout";
import AppSuspense from "../lib/suspense";

function PublicRoute({ children, currentSession, ...rest }) {
  if (currentSession) {
    if (currentSession.state === "OK") {
      return (
        <Route {...rest}>
          <PublicLayout location={rest.location}>
            <AppSuspense>{children}</AppSuspense>
          </PublicLayout>
        </Route>
      );
    }

    if (currentSession.state === "LOCKED") {
      return <Redirect to="/locked-account" />;
    }

    return <Redirect to="/confirm-account" />;
  }

  return <Redirect to={`/login?redirectAfter=${window.location.pathname}${window.location.search}`} />;
}

function mapStateToProps(state) {
  return {
    currentSession: state.account.currentSession,
  };
}

export default connect(mapStateToProps)(PublicRoute);
