import * as types from "./types";

export function setModules(modules) {
  return {
    type: types.MODULES_SET_MODULES,
    state: { modules },
  };
}

export default setModules;
