import { useEffect } from "react";
import { connect } from "react-redux";
import * as centrifuge from "../boot/centrifuge";

function CentrifugeLoader({ children, currentSession, authTokens }) {
  useEffect(
    () => () => {
      centrifuge.disconnect();
    },
    [],
  );

  useEffect(() => {
    if (currentSession && authTokens) {
      centrifuge.connect(authTokens.token);
    }
  }, [currentSession, authTokens]);

  useEffect(() => {
    if (authTokens && authTokens.token) {
      centrifuge.setToken(authTokens.token);
    }
  }, [authTokens]);

  return children;
}

function mapStateToProps(state) {
  return {
    currentSession: state.account.currentSession,
    authTokens: state.account.authTokens,
  };
}

export default connect(mapStateToProps)(CentrifugeLoader);
