import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { configure as configureApi } from "../boot/api";
import { configure as configureApiV2 } from "../boot/apiv2";
import { load as loadConfig } from "../boot/config";

import { setModules } from "../actions/modules";

function ConfigLoader({ children, setModules }) {
  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const cfg = await loadConfig();
        configureApi(cfg.api_url);
        configureApiV2(cfg.api_url);
        setModules(cfg.modules);
      } catch (err) {
        console.error(err);
        setErrored(true);
      }
      setLoading(false);
    })();
  }, []);

  if (errored) {
    return (
      <div>
        App failed to fetch configuration. If you are an administrator, please check configuration.
      </div>
    );
  }

  if (loading) {
    return null;
  }

  return children;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setModules }, dispatch);
}

export default connect(null, mapDispatchToProps)(ConfigLoader);
