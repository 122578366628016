import React from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import {
  BookOutlined,
  CarOutlined,
  DiffOutlined,
  KeyOutlined,
  LinkOutlined,
  MailOutlined,
  PoweroffOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  RetweetOutlined,
  SnippetsOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";

import AppBreadcrump from "./AppBreadcrump";
import BaseLayout from "./BaseLayout";
import UserLabel from "./utils/users/UserLabel";

import { signOut } from "../boot/auth";

function PublicLayout({ children, currentUser, modules, ...props }) {
  const history = useHistory();

  const whiteColor = {
    color: `${(modules.organisation_name === "Ema" || modules.organisation_name === "Keematic") && "white"}`,
  };

  const siderMenuItems = [
    ...(modules.bookings
      ? [
        {
          key: "bookings",
          icon: <BookOutlined style={whiteColor} />,
          label: <Link to="/public/bookings" style={whiteColor}>Mes réservations</Link>,
        },
      ]
      : []),
    {
      key: "items",
      icon: <CarOutlined style={whiteColor} />,
      label: <Link to="/public/items" style={whiteColor}>Mes véhicules</Link>,
    },
    ...(modules.bookings && !modules.disable_invitations
      ? [
        {
          key: "booking-invitations",
          icon: <MailOutlined style={whiteColor} />,
          label: <Link to="/public/booking-invitations" style={whiteColor}>Mes invitations</Link>,
        },
      ]
      : []),
    ...(modules.bookings && modules.carpooling
      ? [
        {
          key: "carpool",
          icon: <UsergroupAddOutlined style={whiteColor} />,
          label: <Link to="/public/carpool" style={whiteColor}>Covoiturage</Link>,
        },
      ]
      : []),
    ...(modules.bookings && !modules.disable_driver_transfers
      ? [
        {
          key: "driver-requests",
          icon: <KeyOutlined style={whiteColor} />,
          label: <Link to="/public/driver-requests" style={whiteColor}>Demandes de changement de conducteur</Link>,
        },
      ]
      : []),
    ...(!modules.disable_mileage_requests && modules.bookings
      ? [
        {
          key: "mileage_requests",
          icon: <DiffOutlined style={whiteColor} />,
          label: <Link to="/public/mileage-requests" style={whiteColor}>Demandes kilométrage</Link>,
        },
      ]
      : []),
    ...(modules.bookings && !modules.disable_no_items_certs
      ? [
        {
          key: "no_items_certs",
          icon: <SnippetsOutlined style={whiteColor} />,
          label: <Link to="/public/no-items-certificates" style={whiteColor}>Certificats de non-disponibilités</Link>,
        },
      ]
      : []),
    ...(modules.items_transfers
      ? [
        {
          key: "items_transfers",
          icon: <RetweetOutlined style={whiteColor} />,
          label: <Link to="/public/item-transfers" style={whiteColor}>Demandes de transferts de clé</Link>,
        },
      ]
      : []),
    {
      key: "versions",
      icon: <ReadOutlined style={whiteColor} />,
      label: <Link to="/public/versions" style={whiteColor}>Notes de mise à jour</Link>,
    },
  ];

  const headerContent = (
    <>
      <div style={{ display: "flex", alignItems: "center", paddingLeft: 16 }}>
        <LinkOutlined
          style={modules.organisation_name === "Keematic" ? { color: "white", marginRight: 16 } : { marginRight: 16 }}
        />
        <AppBreadcrump />
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        {currentUser.role_level <= 10 && (
          <Link to="/admin">
            <Button danger style={{ marginRight: "16px" }}>
              <LinkOutlined /> {currentUser.role_level === 10 ? "Accès manager" : "Accès administrateur"}
            </Button>
          </Link>
        )}

        <Tooltip title="Mon compte">
          <div style={{ cursor: "pointer" }} onClick={() => history.push("/public/account")}>
            <UserLabel
              popover={false}
              style={{
                marginRight: "8px",
                paddingLeft: "8px",
                borderLeft: "1px solid rgba(0,0,0,0.1)",
                color: `${modules.organisation_name === "Keematic" && "white"}`,
              }}
              user={currentUser}
            />
          </div>
        </Tooltip>

        {!modules.use_acses_boxs && (
          <Tooltip title="Mon code d'authentification">
            <Link
              to="/public/account?tab=authCode"
              style={{
                borderLeft: "1px solid rgba(0,0,0,0.1)",
                height: "100%",
              }}
            >
              <Button
                type="text"
                style={{
                  height: "100%",
                }}
              >
                <QrcodeOutlined
                  style={modules.organisation_name === "Keematic" ? { color: "white", fontSize: 24 } : { fontSize: 24 }}
                />
              </Button>
            </Link>
          </Tooltip>
        )}

        <Tooltip title="Guide d'utilisation">
          <a
            href="https://wiki.carpool.keematic.com/fr/public/home"
            target="_blank"
            rel="noreferrer"
            style={{
              borderLeft: "1px solid rgba(0,0,0,0.1)",
              height: "100%",
            }}
          >
            <Button
              type="text"
              style={{
                height: "100%",
              }}
            >
              <QuestionCircleOutlined
                style={modules.organisation_name === "Keematic" ? { color: "white", fontSize: 24 } : { fontSize: 24 }}
              />
            </Button>
          </a>
        </Tooltip>

        <Tooltip title="Déconnexion" placement="bottomLeft">
          <Button
            type="text"
            style={{
              width: "70px",
              height: "100%",
              borderLeft: "1px solid rgba(0,0,0,0.1)",
            }}
            onClick={async () => {
              await signOut();
              history.push("/login");
            }}
          >
            <PoweroffOutlined
              style={modules.organisation_name === "Keematic"
                ? { color: "white", fontSize: "20px" }
                : { fontSize: "20px" }}
            />
          </Button>
        </Tooltip>
      </div>
    </>
  );

  return (
    <BaseLayout
      logoLink="/public"
      siderMenuItems={siderMenuItems}
      headerContent={headerContent}
      siderMenuTheme="light"
      {...props}
    >
      {children}
    </BaseLayout>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.account.currentSession,
    modules: state.modules.modules,
  };
}

export default connect(mapStateToProps)(PublicLayout);
