import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";

import AppSuspense from "../lib/suspense";

import AdminRoute from "./AdminRoute";
import AuthRoute from "./AuthRoute";
import PublicRoute from "./PublicRoute";

const Index = lazy(() => import("../containers/Index"));
const NotFound = lazy(() => import("../containers/NotFound"));

// Auth
const AuthLogin = lazy(() => import("../containers/auth/Login"));
const AuthConfirmAccount = lazy(() => import("../containers/auth/ConfirmAccount"));
const AuthForgotPassword = lazy(() => import("../containers/auth/ForgotPassword"));
const AuthNewPassword = lazy(() => import("../containers/auth/NewPassword"));
const AuthLockedAccount = lazy(() => import("../containers/auth/LockedAccount"));

const AuthOAuthCMANormandie = lazy(() => import("../containers/auth/oauth/cmanormandie/OAuthLogin"));

const AuthOAuthPaysSaintGillesCallback = lazy(() => import("../containers/auth/oauth/payssaintgilles/Callback"));

// Shared
const SharedEditAccount = lazy(() => import("../containers/shared/account/Edit"));
const SharedVersions = lazy(() => import("../containers/shared/Versions"));

// Admin
const AdminIndex = lazy(() => import("../containers/admin/Index"));

// Items
const AdminItemsList = lazy(() => import("../containers/admin/items/List"));
const AdminItemsNew = lazy(() => import("../containers/admin/items/New"));
const AdminItemsShow = lazy(() => import("../containers/admin/items/Show"));
const AdminItemsEdit = lazy(() => import("../containers/admin/items/Edit"));

const AdminItemsRTMap = lazy(() => import("../containers/admin/items/RTMap"));

// Item Interventions
const AdminItemInterventionsList = lazy(() => import("../containers/admin/item_interventions/List"));

// Item Incidents
const AdminItemIncidentShow = lazy(() => import("../containers/admin/items/incidents/Show"));
const AdminItemIncidentList = lazy(() => import("../containers/admin/items_incidents/List"));

// Item Return Alerts
const AdminItemsReturnAlertsList = lazy(() => import("../containers/admin/items_return_alerts/List"));

// Item Indicators Alerts
const AdminItemsIndicatorsAlertsList = lazy(() => import("../containers/admin/item_indications_alerts/List"));

// Items groups
const AdminItemsGroupsList = lazy(() => import("../containers/admin/items_groups/List"));
const AdminItemsGroupsShow = lazy(() => import("../containers/admin/items_groups/Show"));

// Rolling laws
const AdminRollingLawsIndex = lazy(() => import("../containers/admin/rolling_laws/List"));

const AdminRollingLawsShow = lazy(() => import("../containers/admin/rolling_laws/Show"));

// Accesses
const AdminAccessesList = lazy(() => import("../containers/admin/accesses/List"));
const AdminAccessesNew = lazy(() => import("../containers/admin/accesses/New"));
const AdminAccessesShow = lazy(() => import("../containers/admin/accesses/Show"));
const AdminAccessesEdit = lazy(() => import("../containers/admin/accesses/Edit"));

// Users
const AdminUsersList = lazy(() => import("../containers/admin/users/List"));
const AdminUsersNew = lazy(() => import("../containers/admin/users/New"));
const AdminUsersShow = lazy(() => import("../containers/admin/users/Show"));
const AdminUsersEdit = lazy(() => import("../containers/admin/users/Edit"));

// Bookings
const AdminBookingsList = lazy(() => import("../containers/admin/bookings/List"));
const AdminBookingsCalendar = lazy(() => import("../containers/admin/bookings/Calendar"));
const AdminBookingsShow = lazy(() => import("../containers/admin/bookings/Show"));
const AdminBookingsEdit = lazy(() => import("../containers/admin/bookings/Edit"));
const AdminBookingsNew = lazy(() => import("../containers/admin/bookings/New"));
const AdminBookingValidations = lazy(() => import("../containers/admin/booking_validations/List"));

// No items certificates
const AdminNoItemsCertsList = lazy(() => import("../containers/admin/no_items_certs/List"));
const AdminNoItemsCertsNew = lazy(() => import("../containers/admin/no_items_certs/New"));

// Booking Restrictions
const AdminBookingRestrictionsList = lazy(() => import("../containers/admin/booking_restrictions/List"));
const AdminBookingRestrictionsNew = lazy(() => import("../containers/admin/booking_restrictions/New"));
const AdminBookingRestrictionsEdit = lazy(() => import("../containers/admin/booking_restrictions/Edit"));

// Booking Projects
const AdminBookingProjectsIndex = lazy(() => import("../containers/admin/booking_projects/List"));

const AdminBookingProjectsShow = lazy(() => import("../containers/admin/booking_projects/Show"));

// Brands
const AdminBrandsList = lazy(() => import("../containers/admin/brands/List"));

// Colors
const AdminColorsList = lazy(() => import("../containers/admin/colors/List"));

// Sites
const AdminSitesList = lazy(() => import("../containers/admin/sites/List"));
const AdminSitesNew = lazy(() => import("../containers/admin/sites/New"));
const AdminSitesShow = lazy(() => import("../containers/admin/sites/Show"));
const AdminSitesEdit = lazy(() => import("../containers/admin/sites/Edit"));

// KBoxs
const AdminKBoxsList = lazy(() => import("../containers/admin/kboxs/List"));
const AdminKBoxsNew = lazy(() => import("../containers/admin/kboxs/New"));
const AdminKBoxsEdit = lazy(() => import("../containers/admin/kboxs/Edit"));
const AdminKBoxsShow = lazy(() => import("../containers/admin/kboxs/Show"));

// Users groups
const AdminUsersGroupsList = lazy(() => import("../containers/admin/users_groups/List"));
const AdminUsersGroupsShow = lazy(() => import("../containers/admin/users_groups/Show"));

// Item uses
const AdminItemsUsesList = lazy(() => import("../containers/admin/items_uses/List"));
const AdminItemsUsesChronogram = lazy(() => import("../containers/admin/items_uses/Chronogram"));
const AdminItemsUsesIndex = lazy(() => import("../containers/admin/items_uses/Index"));

// Settings
const AdminItemsOrderingIndex = lazy(() => import("../containers/admin/items_ordering/Index"));

// Pricing Groups
const AdminPricingGroupsIndex = lazy(() => import("../containers/admin/pricing_groups/List"));
const AdminPricingGroupsShow = lazy(() => import("../containers/admin/pricing_groups/Show"));

// Trips
const AdminTripsList = lazy(() => import("../containers/admin/trips/List"));
const AdminTripsShow = lazy(() => import("../containers/admin/trips/Show"));

// Public *************************************************

const PublicIndex = lazy(() => import("../containers/public/Index"));

// Items
const PublicItemsList = lazy(() => import("../containers/public/items/List"));
const PublicItemsShow = lazy(() => import("../containers/public/items/Show"));

// Bookings
const PublicBookingsList = lazy(() => import("../containers/public/bookings/List"));
const PublicBookingsShow = lazy(() => import("../containers/public/bookings/Show"));
const PublicBookingsNew = lazy(() => import("../containers/public/bookings/New"));
const PublicBookingsNewInformations = lazy(() => import("../containers/public/bookings/new/Informations"));
const PublicBookingsNewItems = lazy(() => import("../containers/public/bookings/new/Items"));
const PublicBookingsNewSteps = lazy(() => import("../containers/public/bookings/new/Steps"));
const PublicBookingsNewConfirm = lazy(() => import("../containers/public/bookings/new/Confirm"));

// Carpool
const PublicCarpoolList = lazy(() => import("../containers/public/carpool/List"));

// Mileage Requests
const PublicMileageRequestsList = lazy(() => import("../containers/public/mileage_requests/List"));

// Item Transfers
const PublicItemTransfersList = lazy(() => import("../containers/public/item_transfers/List"));

// Driver Requests
const PublicDriverRequestsList = lazy(() => import("../containers/public/driver_requests/List"));

// Booking Invitations
const PublicBookingInvitationsList = lazy(() => import("../containers/public/booking_invitations/List"));

// No Items Certificates
const PublicNoItemsCertsList = lazy(() => import("../containers/public/no_items_certs/List"));

export default function Routes() {
  return (
    <Switch>
      {/* Index ================================================================================ */}

      <Route exact path="/">
        <AppSuspense>
          <Index />
        </AppSuspense>
      </Route>

      {/* Auth ================================================================================= */}

      <AuthRoute exact path="/login">
        <AuthLogin />
      </AuthRoute>

      <AuthRoute exact path="/confirm-account">
        <AuthConfirmAccount />
      </AuthRoute>

      <AuthRoute exact path="/forgot-password">
        <AuthForgotPassword />
      </AuthRoute>

      <AuthRoute exact path="/new-password">
        <AuthNewPassword />
      </AuthRoute>

      <AuthRoute exact path="/locked-account">
        <AuthLockedAccount />
      </AuthRoute>

      <AuthRoute exact path="/oauth2/callback">
        <AuthOAuthCMANormandie />
      </AuthRoute>

      <AuthRoute exact path="/oauth2/payssaintgilles/callback">
        <AuthOAuthPaysSaintGillesCallback />
      </AuthRoute>

      {/* Shared =============================================================================== */}

      <AdminRoute exact path="/admin/account">
        <SharedEditAccount />
      </AdminRoute>

      <PublicRoute exact path="/public/account">
        <SharedEditAccount />
      </PublicRoute>

      <AdminRoute exact path="/admin/versions">
        <SharedVersions />
      </AdminRoute>

      <PublicRoute exact path="/public/versions">
        <SharedVersions />
      </PublicRoute>

      {/* Admin ================================================================================ */}

      <AdminRoute exact path="/admin">
        <AdminIndex />
      </AdminRoute>

      {/* Item Incidents */}
      <AdminRoute exact path="/admin/items/incidents">
        <AdminItemIncidentList />
      </AdminRoute>

      <AdminRoute exact path="/admin/items/incidents/:id">
        <AdminItemIncidentShow />
      </AdminRoute>

      {/* Item Interventions */}
      <AdminRoute exact path="/admin/items/interventions">
        <AdminItemInterventionsList />
      </AdminRoute>

      {/* Items */}
      <AdminRoute exact path="/admin/items">
        <AdminItemsList />
      </AdminRoute>

      <AdminRoute exact path="/admin/items/new">
        <AdminItemsNew />
      </AdminRoute>

      <AdminRoute exact path="/admin/items/map">
        <AdminItemsRTMap />
      </AdminRoute>

      <AdminRoute exact path="/admin/items/:id">
        <AdminItemsShow />
      </AdminRoute>

      <AdminRoute exact path="/admin/items/:id/edit">
        <AdminItemsEdit />
      </AdminRoute>

      {/* Items Return Alerts */}
      <AdminRoute exact path="/admin/items-return-alerts">
        <AdminItemsReturnAlertsList />
      </AdminRoute>

      {/* Items Indicators Alerts */}
      <AdminRoute exact path="/admin/item-indicators-alerts">
        <AdminItemsIndicatorsAlertsList />
      </AdminRoute>

      {/* Items Groups */}

      <AdminRoute exact path="/admin/items-groups">
        <AdminItemsGroupsList />
      </AdminRoute>

      <AdminRoute exact path="/admin/items-groups/:id">
        <AdminItemsGroupsShow />
      </AdminRoute>

      {/* Booking Projects */}
      <AdminRoute exact path="/admin/rolling-laws">
        <AdminRollingLawsIndex />
      </AdminRoute>

      <AdminRoute exact path="/admin/rolling-laws/:id">
        <AdminRollingLawsShow />
      </AdminRoute>

      {/* Accesses */}

      <AdminRoute exact path="/admin/accesses">
        <AdminAccessesList />
      </AdminRoute>

      <AdminRoute exact path="/admin/accesses/new">
        <AdminAccessesNew />
      </AdminRoute>

      <AdminRoute exact path="/admin/accesses/:id">
        <AdminAccessesShow />
      </AdminRoute>

      <AdminRoute exact path="/admin/accesses/:id/edit">
        <AdminAccessesEdit />
      </AdminRoute>

      {/* Booking Restrictions */}

      <AdminRoute exact path="/admin/bookings/restrictions">
        <AdminBookingRestrictionsList />
      </AdminRoute>

      <AdminRoute exact path="/admin/bookings/restrictions/new">
        <AdminBookingRestrictionsNew />
      </AdminRoute>

      <AdminRoute exact path="/admin/bookings/restrictions/:id/edit">
        <AdminBookingRestrictionsEdit />
      </AdminRoute>

      {/* No items certificates */}

      <AdminRoute exact path="/admin/bookings/no-items-certificates">
        <AdminNoItemsCertsList />
      </AdminRoute>

      <AdminRoute exact path="/admin/bookings/no-items-certificates/new">
        <AdminNoItemsCertsNew />
      </AdminRoute>

      {/* Bookings */}

      <AdminRoute exact path="/admin/bookings">
        <AdminBookingsList />
      </AdminRoute>

      <AdminRoute exact path="/admin/bookings/calendar">
        <AdminBookingsCalendar />
      </AdminRoute>

      <AdminRoute exact path="/admin/bookings/new">
        <AdminBookingsNew />
      </AdminRoute>

      <AdminRoute exact path="/admin/bookings/validations">
        <AdminBookingValidations />
      </AdminRoute>

      <AdminRoute exact path="/admin/bookings/:id">
        <AdminBookingsShow />
      </AdminRoute>

      <AdminRoute exact path="/admin/bookings/:id/edit">
        <AdminBookingsEdit />
      </AdminRoute>

      {/* Booking Projects */}
      <AdminRoute exact path="/admin/booking-projects">
        <AdminBookingProjectsIndex />
      </AdminRoute>

      <AdminRoute exact path="/admin/booking-projects/:id">
        <AdminBookingProjectsShow />
      </AdminRoute>

      {/* Users */}

      <AdminRoute exact path="/admin/users">
        <AdminUsersList />
      </AdminRoute>

      <AdminRoute exact path="/admin/users/new">
        <AdminUsersNew />
      </AdminRoute>

      <AdminRoute exact path="/admin/users/:id">
        <AdminUsersShow />
      </AdminRoute>

      <AdminRoute exact path="/admin/users/:id/edit">
        <AdminUsersEdit />
      </AdminRoute>

      {/* Users Groups */}

      <AdminRoute exact path="/admin/users-groups">
        <AdminUsersGroupsList />
      </AdminRoute>

      <AdminRoute exact path="/admin/users-groups/:id">
        <AdminUsersGroupsShow />
      </AdminRoute>

      {/* Brands */}

      <AdminRoute exact path="/admin/brands">
        <AdminBrandsList />
      </AdminRoute>

      {/* Colors */}

      <AdminRoute exact path="/admin/colors">
        <AdminColorsList />
      </AdminRoute>

      {/* Sites */}

      <AdminRoute exact path="/admin/sites">
        <AdminSitesList />
      </AdminRoute>

      <AdminRoute exact path="/admin/sites/new">
        <AdminSitesNew />
      </AdminRoute>

      <AdminRoute exact path="/admin/sites/:id">
        <AdminSitesShow />
      </AdminRoute>

      <AdminRoute exact path="/admin/sites/:id/edit">
        <AdminSitesEdit />
      </AdminRoute>

      {/* KBoxs */}

      <AdminRoute exact path="/admin/kboxs">
        <AdminKBoxsList />
      </AdminRoute>

      <AdminRoute exact path="/admin/kboxs/new">
        <AdminKBoxsNew />
      </AdminRoute>

      <AdminRoute exact path="/admin/kboxs/:id">
        <AdminKBoxsShow />
      </AdminRoute>

      <AdminRoute exact path="/admin/kboxs/:id/edit">
        <AdminKBoxsEdit />
      </AdminRoute>

      {/* Item uses */}

      <AdminRoute exact path="/admin/items-uses">
        <AdminItemsUsesIndex />
      </AdminRoute>

      <AdminRoute exact path="/admin/items-uses/history">
        <AdminItemsUsesList />
      </AdminRoute>

      <AdminRoute exact path="/admin/items-uses/chronogram">
        <AdminItemsUsesChronogram />
      </AdminRoute>

      {/* Settings */}

      <AdminRoute exact path="/admin/items-ordering">
        <AdminItemsOrderingIndex />
      </AdminRoute>

      {/* Pricing Groups */}
      <AdminRoute exact path="/admin/pricing-groups">
        <AdminPricingGroupsIndex />
      </AdminRoute>

      <AdminRoute exact path="/admin/pricing-groups/:id">
        <AdminPricingGroupsShow />
      </AdminRoute>

      {/* Trips */}
      <AdminRoute exact path="/admin/trips">
        <AdminTripsList />
      </AdminRoute>

      <AdminRoute exact path="/admin/trips/:id">
        <AdminTripsShow />
      </AdminRoute>

      {/* Public =============================================================================== */}

      <PublicRoute exact path="/public">
        <PublicIndex />
      </PublicRoute>

      {/* Items */}

      <PublicRoute exact path="/public/items">
        <PublicItemsList />
      </PublicRoute>

      <PublicRoute exact path="/public/items/:id">
        <PublicItemsShow />
      </PublicRoute>

      {/* Bookings */}

      <PublicRoute exact path="/public/bookings">
        <PublicBookingsList />
      </PublicRoute>

      <PublicRoute exact path="/public/bookings/new">
        <PublicBookingsNew />
      </PublicRoute>

      <PublicRoute exact path="/public/bookings/new/informations">
        <PublicBookingsNewInformations />
      </PublicRoute>

      <PublicRoute exact path="/public/bookings/new/items">
        <PublicBookingsNewItems />
      </PublicRoute>

      <PublicRoute exact path="/public/bookings/new/steps">
        <PublicBookingsNewSteps />
      </PublicRoute>

      <PublicRoute exact path="/public/bookings/new/confirm">
        <PublicBookingsNewConfirm />
      </PublicRoute>

      <PublicRoute exact path="/public/bookings/:id">
        <PublicBookingsShow />
      </PublicRoute>

      {/* Carpool */}

      <PublicRoute exact path="/public/carpool">
        <PublicCarpoolList />
      </PublicRoute>

      {/* Mileage requests */}

      <PublicRoute exact path="/public/mileage-requests">
        <PublicMileageRequestsList />
      </PublicRoute>

      {/* Item Transfers */}

      <PublicRoute exact path="/public/item-transfers">
        <PublicItemTransfersList />
      </PublicRoute>

      {/* Driver Requests */}

      <PublicRoute exact path="/public/driver-requests">
        <PublicDriverRequestsList />
      </PublicRoute>

      {/* Booking Invitations */}

      <PublicRoute exact path="/public/booking-invitations">
        <PublicBookingInvitationsList />
      </PublicRoute>

      {/* Booking Invitations */}

      <PublicRoute exact path="/public/no-items-certificates">
        <PublicNoItemsCertsList />
      </PublicRoute>

      {/* Not Found ============================================================================= */}

      <Route path="*">
        <AppSuspense>
          <NotFound />
        </AppSuspense>
      </Route>
    </Switch>
  );
}
