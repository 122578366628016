import React from "react";
import { Route } from "react-router-dom";

import AuthLayout from "../components/AuthLayout";
import AppSuspense from "../lib/suspense";

export default function AuthRoute({ children, ...rest }) {
  return (
    <Route {...rest}>
      <AuthLayout location={rest.location}>
        <AppSuspense>{children}</AppSuspense>
      </AuthLayout>
    </Route>
  );
}
