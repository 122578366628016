import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";

import { Layout, Menu } from "antd";

import logoEma from "../../images/ema_assets/logo.svg";
import logoKeematic from "../../images/logo.svg";
import MainLayout from "./MainLayout";

import { saveMenuCollapsed } from "../actions/account";
import storage from "../boot/storage";

const { Sider, Header, Footer } = Layout;

function BaseLayout({
  children,
  logoLink,
  siderMenuItems,
  headerContent,
  siderMenuTheme,
  menuCollapsed,
  saveMenuCollapsed,
  modules,
  ...props
}) {
  const [collapsed, setCollapsed] = useState(menuCollapsed || true);
  const [logo, setLogo] = useState(null);

  const location = useLocation();
  const isInPublic = location.pathname.includes("/public");

  useEffect(() => {
    if (menuCollapsed === null) {
      storage.getItem("layout/main-menu-collapsed", (err, val) => {
        if (!err) {
          handleCollapse(val);
        }
      });
    }

    if (modules && modules.organisation_name) {
      chooseIcon(modules.organisation_name);
    }
  }, []);

  const handleCollapse = (val) => {
    setCollapsed(val);
    saveMenuCollapsed(val);
    storage.setItem("layout/main-menu-collapsed", val);
  };

  const chooseIcon = (name) => {
    switch (name) {
      case "Keematic":
        setLogo(logoKeematic);
        break;
      case "Ema":
        setLogo(logoEma);
        break;
      default:
        setLogo(logoKeematic);
    }
  };

  const siderStyles = {
    overflow: "auto",
    height: "100vh",
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
  };

  if (siderMenuTheme === "light") {
    Object.assign(siderStyles, {
      backgroundColor: `${
        (modules.organisation_name === "Ema" || modules.organisation_name === "Keematic") ? "#343434" : "#fff"
      }`,
      borderRight: "1px solid rgba(0,0,0,0.1)",
    });
  }

  return (
    <MainLayout {...props}>
      <Layout hasSider>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={handleCollapse}
          style={siderStyles}
          theme={siderMenuTheme}
        >
          <Link to={logoLink}>
            <div
              title={`${modules.organisation_name} Carpool`}
              style={{
                height: "64px",
                backgroundImage: `url(${logo})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: `auto ${modules.organisation_name !== "Keematic" ? "16" : "35"}px`,
              }}
            />
          </Link>

          <Menu
            subMenuOpenDelay={0.01}
            subMenuCloseDelay={0.01}
            selectable={false}
            theme={siderMenuTheme}
            mode="inline"
            style={{ background: "none" }}
            items={siderMenuItems}
          />
        </Sider>

        <Layout
          style={{
            marginLeft: collapsed ? 80 : 200,
            transition: "margin 0.3s",
          }}
        >
          <Header
            style={{
              position: "sticky",
              top: 0,
              zIndex: 999,
              width: "100%",
              backgroundColor: `${
                (modules.organisation_name === "Ema" && isInPublic)
                  ? "#fdd044"
                  : (modules.organisation_name === "Keematic" && isInPublic)
                  ? "#83479a"
                  : "#fff"
              }`,
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 0,
              paddingRight: 0,
              borderBottom: "1px solid rgba(0,0,0,.15)",
            }}
          >
            {headerContent}
          </Header>

          <div
            style={{
              minHeight: "calc(100vh - 64px)",
              overflow: "initial",
            }}
          >
            {children}
          </div>

          <Footer style={{ textAlign: "center" }}>
            {modules.organisation_name} ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </MainLayout>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.account.currentSession,
    modules: state.modules.modules,
    menuCollapsed: state.account.menuCollapsed,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ saveMenuCollapsed }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
