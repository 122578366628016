export default {
  topBar: {
    userInterface: "Public interface",
    myAccount: "My account",
    signOut: "Sign out",
    myAuthCode: "My authentication code",
  },
  menu: {
    dashboard: "Dashboard",
    items: {
      title: "Vehicles",
      list: "Vehicles list",
      add: "Add a vehicle",
      groups: "Vehicles groups",
      trips: "Vehicles trips",
      itemReturnAlerts: "No-return alerts",
    },
    accesses: {
      title: "Accesses",
      list: "Accesses list",
      add: "Add access",
    },
    bookings: {
      title: "Bookings",
      list: "Bookings list",
      calendar: "Calendar",
      no_items_certs: "Certificates of non-availability",
      restrictions: "Restrictions",
      validations: "Validations",
    },
    users: {
      title: "Users",
      list: "Users list",
      add: "Add an user",
      groups: "Users groups",
    },
    itemsuses: {
      title: "Statistics",
      dashboard: "Usages dashboard",
      history: "Usages history",
      chronogram: "Chronogram",
    },
    settings: {
      title: "Settings",
      sites: "Sites",
      kboxs: "KBoxs",
      pricingGroups: "Private usage pricing",
      brands: "Brands",
      colors: "Colors",
      itemsOrdering: "Vehicles algorithm",
      updateNotes: "Updates notes",
    },
  },
};
