import api from "./api";

let version = null;

/**
 * Get current version from window.appConfig
 *
 * @return {Object}
 */
export async function getCurrentVersion() {
  if (!version) {
    const data = await fetchNewVersion();
    version = data;
  }

  return version;
}

/**
 * Fetch new version from '/version' from backend
 *
 * @return {Object}
 */
export async function fetchNewVersion() {
  const response = await api().get(`/version`);
  return response.data;
}
