import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import localeData from "dayjs/plugin/localeData";
import objectSupport from "dayjs/plugin/objectSupport";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";

import "dayjs/locale/fr";

export function init() {
  dayjs.locale("fr");
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  dayjs.extend(duration);
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(objectSupport);
  dayjs.extend(isSameOrAfter);
}

export default dayjs;
