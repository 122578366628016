import React from "react";
import { MapProvider } from "react-map-gl";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import AuthLoader from "./components/AuthLoader";
import CentrifugeLoader from "./components/CentrifugeLoader";
import ConfigLoader from "./components/ConfigLoader";
import CrispLoader from "./components/CrispLoader";
import ErrorBoundary from "./components/ErrorBoundary";
import VersionLayout from "./components/VersionLayout";

import Routes from "./routes";

import HotjarLoader from "./components/HotjarLoader";
import history from "./history";
import store from "./store";

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ConfigLoader>
          <VersionLayout>
            <HotjarLoader>
              <AuthLoader>
                <CentrifugeLoader>
                  <CrispLoader>
                    <MapProvider>
                      <Router history={history}>
                        <Routes />
                      </Router>
                    </MapProvider>
                  </CrispLoader>
                </CentrifugeLoader>
              </AuthLoader>
            </HotjarLoader>
          </VersionLayout>
        </ConfigLoader>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
