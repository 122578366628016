import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Button, notification } from "antd";

import * as version from "../boot/version";
import history from "../history";

function VersionLayout({ children }) {
  const primaryLocation = window.location.pathname.split("/")[1];

  useEffect(() => {
    (async () => {
      watchForNewVersion();
    })();
  }, []);

  const watchForNewVersion = async () => {
    let currentVersion = {
      version: null,
      hash: null,
    };

    try {
      currentVersion = await version.getCurrentVersion();
    } catch (err) {
      console.error(err);
    }
    let intervalId;

    const watch = async () => {
      try {
        const newVersion = await version.fetchNewVersion();

        if (newVersion.hash !== currentVersion.hash && newVersion.hash !== "DEV") {
          clearInterval(intervalId);

          notification.warning({
            duration: 0,
            message: "Nouvelle version",
            description: `
              Rechargez la page le plus rapidement possible en cliquant sur cette boîte
              de dialogue ou en utilisant la fonction "recharger" de votre navigateur.
            `.trim(),
            btn: (primaryLocation === "admin" || primaryLocation === "public") && (
              <Button
                shape="round"
                type="primary"
                onClick={() => history.push(`/${primaryLocation}/versions`)}
              >
                Voir les notes de mise à jour
              </Button>
            ),
            onClick: () => {
              document.location.reload(true);
            },
            onClose: () => {
              document.location.reload(true);
            },
          });
        }
      } catch (err) {
        console.error(err);
      }
    };

    intervalId = setInterval(watch, 60000);
    watch();
  };

  return children;
}

export default connect()(VersionLayout);
