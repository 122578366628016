import { useEffect } from "react";
import { connect } from "react-redux";

function CrispLoader({ children, currentSession }) {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === "production") {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "1abddc34-bda3-4882-8764-4e4507c80c37";

      (() => {
        // Build script loading
        const scriptEl = document.createElement("script");
        scriptEl.src = "https://client.crisp.chat/l.js";
        scriptEl.async = 1;

        // Append to 'head'
        document.getElementsByTagName("head")[0].appendChild(scriptEl);
      })();
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === "production") {
      if (window.$crisp && window.$crisp.push) {
        if (currentSession && currentSession.login && currentSession.login.length) {
          window.$crisp.push(["set", "user:nickname", [currentSession.login]]);
        }
      }
    }
  }, [currentSession]);

  return children;
}

function mapStateToProps(state) {
  return {
    currentSession: state.account.currentSession,
  };
}

export default connect(mapStateToProps)(CrispLoader);
