import React, { useEffect } from "react";
import { connect } from "react-redux";
import ScrollTop from "./ScrollTop";

function MainLayout({ children, modules }) {
  useEffect(() => {
    if (modules && modules.organisation_name) {
      const name = modules.organisation_name;
      document.title = `${name} Carpool`;
      document.querySelector("link[rel=\"icon\"]").href = `/${name.toLowerCase()}.ico`;
    }
  }, []);

  return (
    <>
      <ScrollTop />
      {children}
    </>
  );
}

function mapStateToProps(state) {
  return {
    modules: state.modules.modules,
  };
}

export default connect(mapStateToProps)(MainLayout);
