import { useEffect } from "react";
import { get as getConfig } from "../boot/config";
import initHotjar from "../boot/hotjar";

function HotjarLoader({ children }) {
  useEffect(() => {
    const config = getConfig();

    if (config?.modules?.enable_hotjar) {
      initHotjar();
    }
  }, []);

  return children;
}

export default HotjarLoader;
